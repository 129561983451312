<template>
  <div class="main">
    <BlueprintBlackLogo />
    <div class="main-area">
      <div class="uh-oh">Welcome, DIM!</div>

      <div class="try-again">Choose your experience:</div>
      <div class="container">
        <div @click="selected = 'leader'" :class="getClass('leader')">
          <div><img src="../assets/icons/flag.png" alt="" /></div>
          <div class="role">Leader</div>
        </div>
        <div @click="selected = 'new'" :class="getClass('new')">
          <div><img src="../assets/icons/new.png" alt="" /></div>
          <div class="role">New Hire</div>
        </div>
      </div>
      <!-- <div class="button-area"> -->
      <div @click="handleContinue()" class="back">Continue</div>
    </div>
    <!-- <div class="support"></div> -->
    <!-- </div> -->
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";

import BlueprintBlackLogo from "@/components/ui/BlueprintBlackLogo";

// import SimpleInput from "@/components/ui/SimpleInput";
// import thdLogo from "../assets/images/THD_Logo 1.png";

// import AuthAPI from "@/api/AuthAPI";
// import UserAPI from "@/api/UserAPI";
// import { userNames } from "@/store/modules/user";
// import { useCookies } from "vue3-cookies";
const router = useRouter();
const route = useRoute();
// const store = useStore();
function getError() {
  return route.params.error;
}
const selected = ref();
function getClass(role) {
  if (role === "leader" && selected.value === "leader") return "selected box";
  else if (role === "new" && selected.value === "new") return "selected box";
  else return "box";
}
function handleContinue() {
  if (selected.value === "leader") router.push(`/new-hires`);
  if (selected.value === "new") router.push(`/`);
}
function getBack() {
  router.push("/");
}
</script>

<style scoped>
.back {
  align-items: center;
  padding: 0px 15px;
  gap: 20px;
  width: 145px;
  height: 55px;
  background: var(--hd-orange, #f96302);
  border-radius: 31px;
  margin-right: 15px;
  color: white;
  font-size: 1em;
  font-weight: 500;
  font-family: Lato;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.main-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px;
  font-family: Helvetica Neue LT Std;
}
.selected {
  border: solid var(--hd-orange, #f96302) 2px;
}
.box {
  display: flex;
  width: 180px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  border-radius: 20px;
  background: #fff;
  height: 150px;
  margin: 10px;
}
.role {
  margin-bottom: 15px;
  color: #5f6562;
  text-align: center;
  font-family: Helvetica Neue LT Std;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.try-again {
  color: #5f6562;
  text-align: center;
  font-family: Helvetica Neue LT Std;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 75px;
}
.error {
  font-family: Lato;
  font-size: 20px;
}
.uh-oh {
  color: #000;
  text-align: center;
  font-family: Lato;
  font-size: 64px;
  font-style: normal;
  font-weight: 750;
  line-height: normal;
}
.main {
  background: #f4f6f5;
  padding: 50px;
}
</style>
